/* eslint-disable max-len */
export const Close = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M12.5565 2.75398C12.9471 2.36346 12.9471 1.73029 12.5565 1.33977C12.166 0.949244 11.5329 0.949244 11.1423 1.33977L6.89932 5.58279L2.65633 1.3398C2.2658 0.949272 1.63264 0.949272 1.24211 1.3398C0.851588 1.73032 0.851588 2.36349 1.24211 2.75401L5.4851 6.997L1.24284 11.2393C0.852318 11.6298 0.852318 12.263 1.24284 12.6535C1.63337 13.044 2.26653 13.044 2.65706 12.6535L6.89932 8.41121L11.1416 12.6535C11.5321 13.044 12.1653 13.044 12.5558 12.6535C12.9463 12.263 12.9463 11.6298 12.5558 11.2393L8.31353 6.997L12.5565 2.75398Z" fill="currentColor" />
);

export const Plus = () => (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 1C7.55228 1 8 1.44772 8 2V12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12L6 2C6 1.44772 6.44772 1 7 1Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 7C13 7.55228 12.5523 8 12 8L2 8C1.44771 8 1 7.55228 1 7C1 6.44772 1.44772 6 2 6L12 6C12.5523 6 13 6.44772 13 7Z" fill="currentColor" />
  </>
);

export const Settings = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M9.82886 2.41795C9.15448 0.787766 6.84542 0.787766 6.17104 2.41795C5.82835 3.24634 4.97166 3.74096 4.0829 3.62354C2.33393 3.39247 1.1794 5.39218 2.254 6.7913C2.80006 7.50227 2.80006 8.4915 2.254 9.20248C1.1794 10.6016 2.33393 12.6013 4.0829 12.3702C4.97166 12.2528 5.82835 12.7474 6.17104 13.5758C6.84542 15.206 9.15448 15.206 9.82886 13.5758C10.1715 12.7474 11.0282 12.2528 11.917 12.3702C13.666 12.6013 14.8205 10.6016 13.7459 9.20248C13.1998 8.4915 13.1998 7.50227 13.7459 6.7913C14.8205 5.39218 13.666 3.39247 11.917 3.62354C11.0282 3.74096 10.1715 3.24634 9.82886 2.41795ZM7.99995 9.99689C9.10452 9.99689 9.99995 9.10146 9.99995 7.99689C9.99995 6.89232 9.10452 5.99689 7.99995 5.99689C6.89538 5.99689 5.99995 6.89232 5.99995 7.99689C5.99995 9.10146 6.89538 9.99689 7.99995 9.99689Z" fill="currentColor" />
);

export const Bag = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M6.50152 0C4.7763 0 3.43095 1.44324 3.43095 3.15668L3.43095 4.16415H1.50033C1.04009 4.16415 0.666992 4.53725 0.666992 4.99749V14.1642C0.666992 14.6244 1.04009 14.9975 1.50033 14.9975H11.5003C11.9606 14.9975 12.3337 14.6244 12.3337 14.1642V4.99749C12.3337 4.53725 11.9606 4.16415 11.5003 4.16415H9.57209L9.57209 3.15668C9.57209 1.44324 8.22673 0 6.50152 0ZM7.90542 5.83082L7.90543 6.66668C7.90543 7.12691 8.27853 7.50001 8.73876 7.50001C9.199 7.50001 9.5721 7.12691 9.57209 6.66667L9.57209 5.83082H10.667V13.3308H2.33366V5.83082H3.43095L3.43096 6.66668C3.43096 7.12691 3.80405 7.50001 4.26429 7.50001C4.72453 7.50001 5.09762 7.12691 5.09762 6.66667L5.09762 5.83082H7.90542ZM7.90542 4.16415L7.90542 3.15668C7.90542 2.30383 7.24749 1.66667 6.50152 1.66667C5.75555 1.66667 5.09761 2.30383 5.09761 3.15668L5.09762 4.16415H7.90542Z" fill="currentColor" />
);

export const Menu = () => (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C12 2.55228 11.5523 3 11 3L1 3C0.447715 3 -5.8711e-08 2.55228 -1.31134e-07 2C-2.03558e-07 1.44772 0.447715 1 1 1L11 1C11.5523 1 12 1.44772 12 2Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12 6.55228 11.5523 7 11 7L1 7C0.447715 7 -5.8711e-08 6.55228 -1.31134e-07 6C-2.03558e-07 5.44772 0.447715 5 1 5L11 5C11.5523 5 12 5.44772 12 6Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 10C12 10.5523 11.5523 11 11 11L1 11C0.447715 11 -5.8711e-08 10.5523 -1.31134e-07 10C-2.03558e-07 9.44772 0.447715 9 1 9L11 9C11.5523 9 12 9.44772 12 10Z" fill="currentColor" />
  </>
);

export const App = () => (
  <>
    <rect width="2" height="2" rx="0.5" fill="currentColor" />
    <rect y="4" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect y="8" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="4" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="4" y="4" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="4" y="8" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="8" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="8" y="4" width="2" height="2" rx="0.5" fill="currentColor" />
    <rect x="8" y="8" width="2" height="2" rx="0.5" fill="currentColor" />
  </>
);

export const Copy = () => (
  <>
    <g clipPath="url(#clip0_2336_36886)">
      <path d="M0.9 0C0.40275 0 0 0.40275 0 0.9V6.75C0 6.99853 0.201472 7.2 0.45 7.2C0.698528 7.2 0.9 6.99853 0.9 6.75V1.9C0.9 1.34771 1.34772 0.9 1.9 0.9H6.75C6.99853 0.9 7.2 0.698528 7.2 0.45C7.2 0.201472 6.99853 0 6.75 0H0.9ZM2.7 1.8C2.20275 1.8 1.8 2.20275 1.8 2.7V8.1C1.8 8.59725 2.20275 9 2.7 9H8.1C8.59725 9 9 8.59725 9 8.1V2.7C9 2.20275 8.59725 1.8 8.1 1.8H2.7ZM2.7 3.7C2.7 3.14772 3.14772 2.7 3.7 2.7H7.1C7.65228 2.7 8.1 3.14772 8.1 3.7V7.1C8.1 7.65228 7.65228 8.1 7.1 8.1H3.7C3.14772 8.1 2.7 7.65228 2.7 7.1V3.7Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2336_36886">
        <rect width="9" height="9" fill="currentColor" />
      </clipPath>
    </defs>
  </>
);

export const Circle = () => (
  <path d="M3.85714 0C2.44216 0 1.28571 1.22653 1.28571 2.72727C1.28491 2.78751 1.2954 2.84731 1.31658 2.9032C1.33776 2.9591 1.3692 3.00997 1.40907 3.05286C1.44895 3.09576 1.49647 3.12982 1.54886 3.15307C1.60126 3.17632 1.65749 3.18829 1.71429 3.18829C1.77108 3.18829 1.82731 3.17632 1.87971 3.15307C1.9321 3.12982 1.97962 3.09576 2.0195 3.05286C2.05937 3.00997 2.09081 2.9591 2.11199 2.9032C2.13317 2.84731 2.14366 2.78751 2.14286 2.72727C2.14286 1.71801 2.90555 0.909091 3.85714 0.909091H8.14286C9.09445 0.909091 9.85714 1.71801 9.85714 2.72727V3.63636H8.57143L10.2857 5.45455L12 3.63636H10.7143V2.72727C10.7143 1.22653 9.55784 0 8.14286 0H3.85714ZM1.71429 4.54545L0 6.36364H1.28571V7.27273C1.28571 8.77347 2.44216 10 3.85714 10H8.14286C9.55784 10 10.7143 8.77347 10.7143 7.27273C10.7151 7.21249 10.7046 7.15269 10.6834 7.0968C10.6622 7.0409 10.6308 6.99003 10.5909 6.94714C10.551 6.90424 10.5035 6.87018 10.4511 6.84693C10.3987 6.82368 10.3425 6.81171 10.2857 6.81171C10.2289 6.81171 10.1727 6.82368 10.1203 6.84693C10.0679 6.87018 10.0204 6.90424 9.9805 6.94714C9.94063 6.99003 9.90919 7.0409 9.88801 7.0968C9.86683 7.15269 9.85634 7.21249 9.85714 7.27273C9.85714 8.28199 9.09445 9.09091 8.14286 9.09091H3.85714C2.90555 9.09091 2.14286 8.28199 2.14286 7.27273V6.36364H3.42857L1.71429 4.54545Z" fill="currentColor" />
);

export const ArrowLeft = () => (
  <path d="M2.5 5.13397C1.83333 5.51887 1.83333 6.48113 2.5 6.86603L7 9.4641C7.66667 9.849 8.5 9.36788 8.5 8.59808L8.5 3.40192C8.5 2.63212 7.66667 2.151 7 2.5359L2.5 5.13397Z" fill="currentColor" />
);

export const ArrowLeftSmall = () => (
  <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM10 3.5L1 3.5V4.5L10 4.5V3.5Z" fill="currentColor" />
);

export const ArrowRight = () => (
  <path d="M9.5 5.13397C10.1667 5.51887 10.1667 6.48113 9.5 6.86603L5 9.4641C4.33333 9.849 3.5 9.36788 3.5 8.59808L3.5 3.40192C3.5 2.63212 4.33333 2.151 5 2.5359L9.5 5.13397Z" fill="currentColor" />
);

export const Check = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M6.09056 13.3984L1.34884 9.33019C0.929682 8.97057 0.881421 8.33925 1.24104 7.92009C1.60066 7.50093 2.23199 7.45267 2.65114 7.81229L5.90172 10.6012L13.2527 2.33527C13.6198 1.92258 14.2518 1.88555 14.6645 2.25256C15.0772 2.61958 15.1143 3.25166 14.7472 3.66436L6.09056 13.3984Z" fill="currentColor" />
);

export const CheckSmall = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M4.82406 10.3281L1.43238 7.98977C0.977683 7.67629 0.863209 7.05356 1.17669 6.59886C1.49018 6.14417 2.11291 6.02969 2.5676 6.34318L4.50412 7.67829L9.25274 2.33867C9.61976 1.92597 10.2518 1.88894 10.6645 2.25596C11.0772 2.62297 11.1143 3.25506 10.7472 3.66775L4.82406 10.3281Z" fill="currentColor" />
);

export const PlusSmall = () => (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 1C6.55228 1 7 1.44772 7 2L7 10C7 10.5523 6.55228 11 6 11C5.44772 11 5 10.5523 5 10V2C5 1.44772 5.44772 1 6 1Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 6.55228 10.5523 7 10 7L2 7C1.44771 7 1 6.55228 1 6C1 5.44772 1.44772 5 2 5L10 5C10.5523 5 11 5.44772 11 6Z" fill="currentColor" />
  </>
);

export const Search = () => (
  <path d="M7.79687 1.79688C4.49027 1.79688 1.79688 4.49027 1.79688 7.79687C1.79688 11.1035 4.49027 13.7969 7.79687 13.7969C9.23478 13.7969 10.5553 13.2863 11.5902 12.4387L15.1727 16.0211C15.2279 16.0787 15.2942 16.1247 15.3674 16.1563C15.4407 16.188 15.5196 16.2047 15.5994 16.2055C15.6792 16.2064 15.7584 16.1912 15.8323 16.1611C15.9062 16.1309 15.9734 16.0863 16.0298 16.0298C16.0863 15.9734 16.1309 15.9062 16.1611 15.8323C16.1912 15.7584 16.2064 15.6792 16.2055 15.5994C16.2047 15.5196 16.188 15.4407 16.1563 15.3674C16.1247 15.2942 16.0787 15.2279 16.0211 15.1727L12.4387 11.5902C13.2863 10.5553 13.7969 9.23478 13.7969 7.79687C13.7969 4.49027 11.1035 1.79688 7.79687 1.79688ZM7.79687 2.99687C10.4549 2.99687 12.5969 5.1388 12.5969 7.79687C12.5969 10.4549 10.4549 12.5969 7.79687 12.5969C5.1388 12.5969 2.99687 10.4549 2.99687 7.79687C2.99687 5.1388 5.1388 2.99687 7.79687 2.99687Z" fill="currentColor" />
);

export const Info = () => (
  <>
    <circle cx="5.5" cy="5.5" r="5.5" fill="currentColor" />
    <path d="M5.5082 3.97766C5.36612 3.97766 5.2459 3.93035 5.14754 3.83574C5.04918 3.74113 5 3.62549 5 3.48883C5 3.35217 5.04918 3.23653 5.14754 3.14192C5.2459 3.04731 5.36612 3 5.5082 3C5.64481 3 5.75956 3.04731 5.85246 3.14192C5.95082 3.23653 6 3.35217 6 3.48883C6 3.62549 5.95082 3.74113 5.85246 3.83574C5.75956 3.93035 5.64481 3.97766 5.5082 3.97766ZM5.86885 4.67937V8H5.12295V4.67937H5.86885Z" fill="#13151B" />
  </>
);

export const InfoW = () => (
  <>
    <circle cx="5.5" cy="5.5" r="5.5" fill="currentColor" />
    <path d="M5.5082 3.97766C5.36612 3.97766 5.2459 3.93035 5.14754 3.83574C5.04918 3.74113 5 3.62549 5 3.48883C5 3.35217 5.04918 3.23653 5.14754 3.14192C5.2459 3.04731 5.36612 3 5.5082 3C5.64481 3 5.75956 3.04731 5.85246 3.14192C5.95082 3.23653 6 3.35217 6 3.48883C6 3.62549 5.95082 3.74113 5.85246 3.83574C5.75956 3.93035 5.64481 3.97766 5.5082 3.97766ZM5.86885 4.67937V8H5.12295V4.67937H5.86885Z" fill="#FFFFFF" />
  </>
);

export const Clip = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M6.44844 3C5.31625 3 4.39844 3.91782 4.39844 5.05V10.2759C4.39844 11.7804 5.61808 13 7.12258 13H7.89844C9.83144 13 11.3984 11.433 11.3984 9.5L11.3984 4C11.3984 3.44771 11.8462 3 12.3984 3C12.9507 3 13.3984 3.44772 13.3984 4L13.3984 9.5C13.3984 12.5376 10.936 15 7.89844 15H7.12258C4.51351 15 2.39844 12.8849 2.39844 10.2759V5.05C2.39844 2.81325 4.21168 1 6.44844 1C8.68519 1 10.4984 2.81325 10.4984 5.05V8.95C10.4984 10.3583 9.35676 11.5 7.94844 11.5C6.54011 11.5 5.39844 10.3583 5.39844 8.95V6C5.39844 5.44772 5.84615 5 6.39844 5C6.95072 5 7.39844 5.44772 7.39844 6V8.95C7.39844 9.25376 7.64468 9.5 7.94844 9.5C8.25219 9.5 8.49844 9.25376 8.49844 8.95V5.05C8.49844 3.91782 7.58062 3 6.44844 3Z" fill="currentColor" />
);

export const Trash = () => (
  <path d="M1.42031 11C1.19531 11 0.998437 10.9083 0.829687 10.725C0.660937 10.5417 0.576563 10.3278 0.576563 10.0833V1.375H0.421875C0.3 1.375 0.199219 1.33171 0.119531 1.24514C0.0398438 1.15856 0 1.04907 0 0.916667C0 0.784259 0.0398438 0.674769 0.119531 0.588194C0.199219 0.50162 0.3 0.458333 0.421875 0.458333H2.64375C2.64375 0.325926 2.68359 0.216435 2.76328 0.129861C2.84297 0.0432871 2.94375 0 3.06562 0H5.93438C6.05625 0 6.15703 0.0432871 6.23672 0.129861C6.31641 0.216435 6.35625 0.325926 6.35625 0.458333H8.57812C8.7 0.458333 8.80078 0.50162 8.88047 0.588194C8.96016 0.674769 9 0.784259 9 0.916667C9 1.04907 8.96016 1.15856 8.88047 1.24514C8.80078 1.33171 8.7 1.375 8.57812 1.375H8.42344V10.0833C8.42344 10.3278 8.33906 10.5417 8.17031 10.725C8.00156 10.9083 7.80469 11 7.57969 11H1.42031ZM1.42031 1.375V10.0833H7.57969V1.375H1.42031ZM2.91094 8.31111C2.91094 8.44352 2.95078 8.55301 3.03047 8.63958C3.11016 8.72616 3.21094 8.76944 3.33281 8.76944C3.45469 8.76944 3.55547 8.72616 3.63516 8.63958C3.71484 8.55301 3.75469 8.44352 3.75469 8.31111V3.13194C3.75469 2.99954 3.71484 2.89005 3.63516 2.80347C3.55547 2.7169 3.45469 2.67361 3.33281 2.67361C3.21094 2.67361 3.11016 2.7169 3.03047 2.80347C2.95078 2.89005 2.91094 2.99954 2.91094 3.13194V8.31111ZM5.24531 8.31111C5.24531 8.44352 5.28516 8.55301 5.36484 8.63958C5.44453 8.72616 5.54531 8.76944 5.66719 8.76944C5.78906 8.76944 5.88984 8.72616 5.96953 8.63958C6.04922 8.55301 6.08906 8.44352 6.08906 8.31111V3.13194C6.08906 2.99954 6.04922 2.89005 5.96953 2.80347C5.88984 2.7169 5.78906 2.67361 5.66719 2.67361C5.54531 2.67361 5.44453 2.7169 5.36484 2.80347C5.28516 2.89005 5.24531 2.99954 5.24531 3.13194V8.31111ZM1.42031 1.375V10.0833V1.375Z" fill="currentColor" />
);

export const Triangle = () => (
  <path d="M3.13397 6.5C3.51887 7.16667 4.48113 7.16667 4.86603 6.5L7.4641 2C7.849 1.33333 7.36788 0.5 6.59808 0.5H1.40192C0.632124 0.5 0.150998 1.33333 0.535898 2L3.13397 6.5Z" fill="currentColor" />
);

export const Dnd = () => (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 4C11 4.55228 10.6269 5 10.1667 5L1.83333 5C1.3731 5 1 4.55228 1 4C1 3.44772 1.3731 3 1.83333 3L10.1667 3C10.6269 3 11 3.44772 11 4Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 8C11 8.55228 10.6269 9 10.1667 9L1.83333 9C1.3731 9 1 8.55228 1 8C1 7.44772 1.3731 7 1.83333 7L10.1667 7C10.6269 7 11 7.44772 11 8Z" fill="currentColor" />
  </>
);

export const Refresh = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M10.837 2.04429C10.6596 2.02565 10.483 2.01295 10.3073 2.00604C9.5017 1.97492 8.69033 2.0648 7.90268 2.27828C5.72768 2.86978 3.89033 4.36364 2.86786 6.37341C2.46216 7.17221 2.1972 8.0283 2.07637 8.90294C2.06443 8.98955 2.05384 9.07666 2.04463 9.16424C2.04167 9.19244 2.03886 9.22061 2.03619 9.24877C1.93592 10.312 2.04726 11.395 2.3762 12.429C2.65403 13.2997 3.07806 14.1104 3.62224 14.829C3.70262 14.9349 3.78602 15.0395 3.87247 15.1425C4.00787 15.3039 4.14828 15.4586 4.29329 15.6065C4.78669 16.1094 5.34735 16.5486 5.96284 16.9089C7.96398 18.0784 10.389 18.3201 12.5779 17.576C13.3783 17.3032 14.1256 16.9061 14.7943 16.4054C14.9124 16.3169 15.0287 16.2246 15.1431 16.1286L15.1568 16.1173C16.6823 14.8317 17.732 12.9774 17.957 10.8367C18.0147 10.2874 18.5068 9.88897 19.056 9.9467C19.6053 10.0044 20.0038 10.4965 19.946 11.0458C19.6457 13.9035 18.1696 16.353 16.0489 17.9642C15.1551 18.6441 14.1797 19.1513 13.1645 19.4894C11.8503 19.9284 10.4234 20.1 8.95552 19.9457C7.52496 19.7953 6.1967 19.3503 5.02468 18.6774C3.86903 18.0138 2.8653 17.1285 2.06521 16.0854C1.33732 15.1377 0.804688 14.0969 0.462898 13.0136C0.0703034 11.7731 -0.0850136 10.4357 0.045037 9.06088C0.0581716 8.92161 0.0742099 8.78261 0.0931447 8.64398C0.249998 7.49346 0.598326 6.41314 1.10234 5.43199C1.69315 4.27993 2.51886 3.22427 3.57295 2.33979C5.5791 0.656428 8.05222 -0.10463 10.4769 0.0115216C13.0259 0.133631 15.5213 1.22525 17.3643 3.23397L18.7508 2.43351C19.0841 2.24106 19.5008 2.48162 19.5008 2.86652V8.06267C19.5008 8.44757 19.0841 8.68814 18.7508 8.49569L14.2508 5.89761C13.9175 5.70516 13.9175 5.22403 14.2508 5.03158L15.5789 4.26481C14.2618 2.98082 12.5891 2.23264 10.8581 2.04629L10.837 2.04429ZM11.0008 5.00049C11.0008 4.44821 10.5531 4.00049 10.0008 4.00049C9.44849 4.00049 9.00078 4.44821 9.00078 5.00049V12.0005H15.0008C15.5531 12.0005 16.0008 11.5528 16.0008 11.0005C16.0008 10.4482 15.5531 10.0005 15.0008 10.0005H11.0008V5.00049Z" />
);

export const Cross = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M10.4725 2.62898C10.863 2.23846 10.863 1.60529 10.4725 1.21477C10.082 0.824244 9.4488 0.824244 9.05827 1.21477L5.84351 4.42954L2.62889 1.21492C2.23836 0.824394 1.6052 0.824395 1.21467 1.21492C0.824149 1.60544 0.824149 2.23861 1.21467 2.62913L4.42929 5.84375L1.21452 9.05852C0.824 9.44904 0.824 10.0822 1.21453 10.4727C1.60505 10.8633 2.23821 10.8633 2.62874 10.4727L5.84351 7.25796L9.05842 10.4729C9.44895 10.8634 10.0821 10.8634 10.4726 10.4729C10.8632 10.0824 10.8632 9.44919 10.4726 9.05867L7.25772 5.84375L10.4725 2.62898Z" fill="currentColor" />
);

export const CheckRounded = () => (
  <>
    <circle cx="10" cy="10" r="9.72973" stroke="white" strokeWidth="0.540541" />
    <g clipPath="url(#clip0_3701_46536)">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.69776 12.6484L6.13467 10.4494C5.9081 10.255 5.88201 9.91374 6.0764 9.68717C6.27079 9.4606 6.61204 9.43451 6.83861 9.6289L8.59568 11.1364L12.5692 6.66835C12.7676 6.44527 13.1093 6.42525 13.3323 6.62364C13.5554 6.82203 13.5754 7.16369 13.377 7.38677L8.69776 12.6484Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3701_46536">
        <rect width="8.64865" height="8.64865" fill="white" transform="translate(5.40527 5.40625)" />
      </clipPath>
    </defs>
  </>
);

export const CloseRounded = () => (
  <>
    <circle cx="10" cy="10" r="9.72973" fill="#840207" fillOpacity="0.2" stroke="#BB0000" strokeWidth="0.540541" />
    <rect width="8.64865" height="8.64865" transform="translate(5.40527 5.40625)" fill="#840207" fillOpacity="0.2" />
    <g clipPath="url(#clip0_3701_46722)">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.3016 7.00368C13.5526 6.75262 13.5526 6.34559 13.3016 6.09454C13.0505 5.84349 12.6435 5.84349 12.3924 6.09454L9.66341 8.82356L6.93727 6.09743C6.68622 5.84637 6.27918 5.84637 6.02813 6.09743C5.77708 6.34848 5.77708 6.75551 6.02813 7.00656L8.75427 9.7327L6.02847 12.4585C5.77742 12.7096 5.77742 13.1166 6.02847 13.3676C6.27952 13.6187 6.68656 13.6187 6.93761 13.3676L9.66341 10.6418L12.3921 13.3705C12.6431 13.6216 13.0502 13.6216 13.3012 13.3705C13.5523 13.1195 13.5523 12.7124 13.3012 12.4614L10.5725 9.7327L13.3016 7.00368Z" fill="#BB0000" />
    </g>
    <defs>
      <clipPath id="clip0_3701_46722">
        <rect width="9" height="9" fill="white" transform="translate(5.22949 5.23438)" />
      </clipPath>
    </defs>
  </>
);

export const CloseSmall = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M8.30157 1.99586C8.55262 1.74481 8.55262 1.33778 8.30157 1.08673C8.05052 0.835675 7.64348 0.835675 7.39243 1.08673L4.66341 3.81575L1.93727 1.08961C1.68622 0.838562 1.27918 0.838562 1.02813 1.08961C0.777081 1.34066 0.777081 1.7477 1.02813 1.99875L3.75427 4.72489L1.02847 7.45069C0.777421 7.70174 0.777421 8.10878 1.02847 8.35983C1.27952 8.61088 1.68656 8.61088 1.93761 8.35983L4.66341 5.63403L7.39209 8.36271C7.64315 8.61376 8.05018 8.61376 8.30123 8.36271C8.55228 8.11166 8.55228 7.70462 8.30123 7.45357L5.57255 4.72489L8.30157 1.99586Z" />
);

export const InfoLight = () => (
  <>
    <circle cx="5.5" cy="5.5" r="5.5" fill="currentColor" />
    <path d="M5.5082 3.97766C5.36612 3.97766 5.2459 3.93035 5.14754 3.83574C5.04918 3.74113 5 3.62549 5 3.48883C5 3.35217 5.04918 3.23653 5.14754 3.14192C5.2459 3.04731 5.36612 3 5.5082 3C5.64481 3 5.75956 3.04731 5.85246 3.14192C5.95082 3.23653 6 3.35217 6 3.48883C6 3.62549 5.95082 3.74113 5.85246 3.83574C5.75956 3.93035 5.64481 3.97766 5.5082 3.97766ZM5.86885 4.67937V8H5.12295V4.67937H5.86885Z" fill="#F6F6F8" />
  </>
);

export const TrashBig = () => (
  <path d="M2.55156 15.5C2.22656 15.5 1.94219 15.375 1.69844 15.125C1.45469 14.875 1.33281 14.5833 1.33281 14.25V2.375H1.10938C0.933333 2.375 0.78776 2.31597 0.672656 2.19792C0.557552 2.07986 0.5 1.93056 0.5 1.75C0.5 1.56944 0.557552 1.42014 0.672656 1.30208C0.78776 1.18403 0.933333 1.125 1.10938 1.125H4.31875C4.31875 0.944444 4.3763 0.795139 4.49141 0.677083C4.60651 0.559028 4.75208 0.5 4.92812 0.5H9.07188C9.24792 0.5 9.39349 0.559028 9.50859 0.677083C9.6237 0.795139 9.68125 0.944444 9.68125 1.125H12.8906C13.0667 1.125 13.2122 1.18403 13.3273 1.30208C13.4424 1.42014 13.5 1.56944 13.5 1.75C13.5 1.93056 13.4424 2.07986 13.3273 2.19792C13.2122 2.31597 13.0667 2.375 12.8906 2.375H12.6672V14.25C12.6672 14.5833 12.5453 14.875 12.3016 15.125C12.0578 15.375 11.7734 15.5 11.4484 15.5H2.55156ZM2.55156 2.375V14.25H11.4484V2.375H2.55156ZM4.70469 11.8333C4.70469 12.0139 4.76224 12.1632 4.87734 12.2812C4.99245 12.3993 5.13802 12.4583 5.31406 12.4583C5.4901 12.4583 5.63568 12.3993 5.75078 12.2812C5.86589 12.1632 5.92344 12.0139 5.92344 11.8333V4.77083C5.92344 4.59028 5.86589 4.44097 5.75078 4.32292C5.63568 4.20486 5.4901 4.14583 5.31406 4.14583C5.13802 4.14583 4.99245 4.20486 4.87734 4.32292C4.76224 4.44097 4.70469 4.59028 4.70469 4.77083V11.8333ZM8.07656 11.8333C8.07656 12.0139 8.13411 12.1632 8.24922 12.2812C8.36432 12.3993 8.5099 12.4583 8.68594 12.4583C8.86198 12.4583 9.00755 12.3993 9.12266 12.2812C9.23776 12.1632 9.29531 12.0139 9.29531 11.8333V4.77083C9.29531 4.59028 9.23776 4.44097 9.12266 4.32292C9.00755 4.20486 8.86198 4.14583 8.68594 4.14583C8.5099 4.14583 8.36432 4.20486 8.24922 4.32292C8.13411 4.44097 8.07656 4.59028 8.07656 4.77083V11.8333ZM2.55156 2.375V14.25V2.375Z" fill="currentColor" />
);

export const CopyBig = () => (
  <path d="M1.4 0C0.6265 0 0 0.6265 0 1.4V10.5C0 10.8866 0.313401 11.2 0.7 11.2C1.0866 11.2 1.4 10.8866 1.4 10.5V2.95556C1.4 2.09645 2.09645 1.4 2.95556 1.4H10.5C10.8866 1.4 11.2 1.0866 11.2 0.7C11.2 0.313401 10.8866 0 10.5 0H1.4ZM4.2 2.8C3.4265 2.8 2.8 3.4265 2.8 4.2V12.6C2.8 13.3735 3.4265 14 4.2 14H12.6C13.3735 14 14 13.3735 14 12.6V4.2C14 3.4265 13.3735 2.8 12.6 2.8H4.2ZM4.2 5.75556C4.2 4.89645 4.89645 4.2 5.75556 4.2H11.0444C11.9036 4.2 12.6 4.89645 12.6 5.75556V11.0444C12.6 11.9036 11.9036 12.6 11.0444 12.6H5.75556C4.89645 12.6 4.2 11.9036 4.2 11.0444V5.75556Z" fill="currentColor" />
);

export const Disabled = () => (
  <path d="M2.90381 2.90381L12.0962 12.0962M1 7.5C1 11.0899 3.91015 14 7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
);

export const Soon = () => (
  <path d="M7 3.72727V6.99973M7 6.99973L9.18182 8.63636M7 6.99973C7 6.9999 6.99986 6.99962 7 6.99973ZM1.15564 8.36364C1.05383 7.92556 1 7.46906 1 7C1 6.53094 1.05383 6.07444 1.15564 5.63636M5.90909 1.09893C6.26285 1.03395 6.62746 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C6.62746 13 6.26285 12.966 5.90909 12.9011M4 1.80269C3.18161 2.2761 2.48542 2.93726 1.97033 3.72727M1.97033 10.2727C2.48542 11.0627 3.18161 11.7239 4 12.1973" stroke="currentColor" strokeWidth="1.09091" strokeLinecap="round" />
);

export const LinkArrow = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M1.43282 0.511428L7.86019 0.511428C8.25461 0.511428 8.57435 0.831164 8.57435 1.22558L8.57435 7.65295C8.57435 8.04737 8.25461 8.36711 7.86019 8.3671C7.46578 8.36711 7.14604 8.04737 7.14604 7.65295L7.14604 2.9497L1.22365 8.87209L0.213686 7.86212L6.13608 1.93973L1.43282 1.93973C1.03841 1.93973 0.718669 1.62 0.718669 1.22558C0.718669 0.831165 1.03841 0.511428 1.43282 0.511428Z" fill="#CD5B04" />
);

export const Minus = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 6.55228 10.5523 7 10 7L2 7C1.44771 7 1 6.55228 1 6C1 5.44772 1.44772 5 2 5L10 5C10.5523 5 11 5.44772 11 6Z" fill="currentColor" />
);

export const Multiple = () => (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.336827 0.336827C0.78593 -0.112276 1.51407 -0.112276 1.96317 0.336827L5.64317 4.01683C6.09228 4.46593 6.09228 5.19407 5.64317 5.64317C5.19407 6.09228 4.46593 6.09228 4.01683 5.64317L0.336827 1.96317C-0.112276 1.51407 -0.112276 0.78593 0.336827 0.336827Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.64317 0.336827C5.19407 -0.112276 4.46593 -0.112276 4.01683 0.336827L0.336827 4.01683C-0.112276 4.46593 -0.112276 5.19407 0.336827 5.64317C0.78593 6.09228 1.51407 6.09228 1.96317 5.64317L5.64317 1.96317C6.09228 1.51407 6.09228 0.78593 5.64317 0.336827Z" fill="currentColor" />
  </>
);

export const App4 = () => (
  <>
    <rect width="4" height="4" rx="0.5" fill="currentColor" />
    <rect y="6" width="4" height="4" rx="0.5" fill="currentColor" />
    <rect x="6" width="4" height="4" rx="0.5" fill="currentColor" />
    <rect x="6" y="6" width="4" height="4" rx="0.5" fill="currentColor" />
  </>
);

export const Download = () => (
  <>
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M10.821 5.04338L6.9321 8.82591C6.69346 9.05803 6.30654 9.05803 6.0679 8.82591L2.17898 5.04338C1.94034 4.81126 1.94034 4.43493 2.17898 4.20282C2.41763 3.9707 2.80454 3.9707 3.04319 4.20282L5.88892 6.9707L5.88892 -2.23413e-07L7.11108 -1.6999e-07L7.11108 6.9707L9.95681 4.20282C10.1955 3.9707 10.5824 3.9707 10.821 4.20282C11.0597 4.43493 11.0597 4.81126 10.821 5.04338Z" />
    <path d="M2 13L11 13" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
  </>
);

// export const