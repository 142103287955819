export const iconParams = {
  close: {
    id: 'close',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  plus: {
    id: 'plus',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  settings: {
    id: 'settings',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  bag: {
    id: 'bag',
    width: 13,
    height: 15,
    viewBox: '0 0 13 15',
  },
  menu: {
    id: 'menu',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  app: {
    id: 'app',
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
  },
  copy: {
    id: 'copy',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  circle: {
    id: 'circle',
    width: 12,
    height: 10,
    viewBox: '0 0 12 10',
  },
  arrow_left: {
    id: 'arrow_left',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  arrow_left_small: {
    id: 'arrow_left_small',
    width: 10,
    height: 8,
    viewBox: '0 0 10 8',
  },
  arrow_right: {
    id: 'arrow_right',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  check: {
    id: 'check',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  check_small: {
    id: 'check_small',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  plus_small: {
    id: 'plus_small',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  search: {
    id: 'search',
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
  },
  info: {
    id: 'info',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  info_w: {
    id: 'info_w',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  clip: {
    id: 'clip',
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
  },
  trash: {
    id: 'trash',
    width: 9,
    height: 11,
    viewBox: '0 0 9 11',
  },
  triangle: {
    id: 'triangle',
    width: 8,
    height: 7,
    viewBox: '0 0 8 7',
  },
  dnd: {
    id: 'dnd',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  refresh: {
    id: 'refresh',
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
  },
  cross: {
    id: 'cross',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  check_rounded: {
    id: 'check_rounded',
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
  },
  close_rounded: {
    id: 'close_rounded',
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
  },
  close_small: {
    id: 'close_small',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  info_light: {
    id: 'info_light',
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
  },
  trash_big: {
    id: 'trash_big',
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
  },
  copy_big: {
    id: 'copy_big',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  disabled: {
    id: 'disabled',
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
  },
  soon: {
    id: 'soon',
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
  },
  link_arrow: {
    id: 'link_arrow',
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
  },
  minus: {
    id: 'minus',
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
  },
  multiple: {
    id: 'multiple',
    width: 6,
    height: 6,
    viewBox: '0 0 6 6',
  },
  'app-4': {
    id: 'app-4',
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
  },
  download: {
    id: 'download',
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
  },
};

export const names = Object.keys(iconParams);
